import React from 'react';
import PropTypes from 'prop-types';
import Card from '@shared/components/card';
import WpComponentWrapper from './wp-component-wrapper';

const ColumnCards = ({ title, cardLeft, cardRight, themeClass }) => {
  return (
    <WpComponentWrapper>
      <div className="container mx-auto px-4">
        <h4 className="text-center mb-6">{title}</h4>
        <div className="flex -mx-4 list-disc">
          <div className="w-1/2 px-4">
            <Card content={cardLeft} classes={`bg-${themeClass} text-${themeClass}-contrast`} />
          </div>
          <div className="w-1/2 px-4">
            <Card content={cardRight} classes={`bg-${themeClass} text-${themeClass}-contrast`} />
          </div>
        </div>
      </div>
    </WpComponentWrapper>
  );
};

ColumnCards.propTypes = {
  themeClass: PropTypes.string,
  footer: PropTypes.string,
  cardLeft: PropTypes.string,
  cardRight: PropTypes.string,
};

ColumnCards.defaultProps = {
  themeClass: 'primary',
};

export default ColumnCards;
