import React from 'react';
import type from 'prop-types';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

function Team({ teamMembers: tm, image }) {
  const mainImg = getImage(image?.localFile);
  const teamMembers = Object.keys(tm)
    .filter((key) => !!tm[key].teamMember)
    .map((key) => ({
      ...tm[key].teamMember,
    }));

  const itemWidth = '150px';

  return (
    <>
      <div className="container mx-auto p-4">
        <h2 className="text-center mb-6">Who we are</h2>
        <div className="flex flex-wrap justify-center">
          <div className="w-5/12 mb-12 md:mb-0">
            <div className="w-full flex justify-center items-center h-full flex-row">
              {mainImg && (
                <GatsbyImage
                  imgClassName="max-h-full max-w-full"
                  style={{ width: `${mainImg?.width}px` }}
                  className="max-w-full"
                  image={mainImg}
                  alt="Team"
                />
              )}
            </div>
          </div>
          <div className="w-full md:w-7/12">
            <div className="flex flex-wrap justify-center mt-10">
              {teamMembers &&
                teamMembers.map((tm) => (
                  <div
                    key={tm.name}
                    className="flex flex-col justify-start content-center text-center justify-self-stretch mb-10 px-4"
                    style={{ width: itemWidth }}
                  >
                    {tm.image?.localFile?.childImageSharp?.gatsbyImageData && (
                      <div
                        className="justify-self-center"
                        style={{ maxWidth: tm?.image?.localFile?.childImageSharp?.original?.width }}
                      >
                        <GatsbyImage
                          image={tm?.image?.localFile?.childImageSharp?.gatsbyImageData}
                          alt={tm.name}
                        />
                      </div>
                    )}
                    <h4 className="text-lg">{tm.name}</h4>
                    <h5 className="text-base mt-0">{tm.jobTitle}</h5>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

Team.propTypes = {
  image: type.object,
  teamMembers: type.array,
};

export default Team;
