import React from 'react';
import PropTypes from 'prop-types';
import WpComponentWrapper from '@shared/components/wp-components/wp-component-wrapper';
import { GatsbyImage } from 'gatsby-plugin-image';
import cx from 'classnames';
import { getRandomValueBetweenXY } from '@shared/utils';

const SplitPanel = ({ contentOrder, themeClass, html, image }) => {
  const imageFluid = image?.localFile?.childImageSharp?.gatsbyImageData || false;
  const imgOriginalWidth = image?.localFile?.childImageSharp?.original.width;
  const imageMaxWidth = imgOriginalWidth && imgOriginalWidth < 500 ? imgOriginalWidth : 500;

  // const newHtml = wpToMaterialUiClassesConverter(html);

  return (
    <WpComponentWrapper themeClass={themeClass}>
      <div
        className={cx(
          'container mx-auto px-4 flex flex-wrap justify-center',
          contentOrder === 'text_image' ? 'flex-row-reverse' : 'flex-row',
        )}
      >
        <div className="flex-none max-w-full md:max-w-1/2">
          <div
            className={cx(
              'items-center h-full flex flex-row', // px-6
              `bg-${themeClass}`,
              'justify-center p-8 rounded-lg', // Prototype
              contentOrder === 'text_image' ? 'justify-start' : 'justify-end',
            )}
            style={{
              borderTopLeftRadius: `${getRandomValueBetweenXY(40, 90)}px ${getRandomValueBetweenXY(
                40,
                90,
              )}px`,
              borderTopRightRadius: `${getRandomValueBetweenXY(40, 90)}px ${getRandomValueBetweenXY(
                40,
                90,
              )}px`,
              borderBottomRightRadius: `${getRandomValueBetweenXY(
                40,
                90,
              )}px ${getRandomValueBetweenXY(40, 90)}px`,
              borderBottomLeftRadius: `${getRandomValueBetweenXY(
                40,
                90,
              )}px ${getRandomValueBetweenXY(40, 90)}px`,
            }}
          >
            <div className="max-w-full text-center">
              {imageFluid ? (
                <GatsbyImage
                  image={imageFluid}
                  alt="Side Image"
                  imgClassName="max-h-full max-w-full"
                  style={{ width: `${imageMaxWidth}px` }}
                  className="max-w-full"
                />
              ) : (
                ''
              )}
            </div>
          </div>
        </div>
        <div className="flex-none max-w-full md:max-w-1/2">
          <div
            className={cx(
              'items-center h-full flex flex-row px-8',
              contentOrder === 'text_image' ? 'justify-end' : 'justify-start',
            )}
          >
            <div
              className="m-0 max-w-md mx-0 py-6 md:py-8 lg:py-0 block text-black"
              dangerouslySetInnerHTML={{ __html: html }}
            ></div>
          </div>
        </div>
      </div>
    </WpComponentWrapper>
  );
};

SplitPanel.propTypes = {
  contentOrder: PropTypes.string,
  themeClass: PropTypes.string,
  image: PropTypes.object,
  html: PropTypes.string,
};

export default SplitPanel;
