import React from 'react';
import PropTypes from 'prop-types';
import WpComponentWrapper from '@shared/components/wp-components/wp-component-wrapper';
import { wpToTwClassesConverter } from '@shared/utils';

const NoImageEditor = ({ themeClass, html }) => {
  const getButtonStylesBasedOnTheme = (theme) => {
    if (theme === 'tertiary') {
      return {
        text: 'text-white',
        bg: 'bg-gray-600',
      };
    }

    if (theme === 'secondary') {
      return {
        text: 'text-white',
        bg: 'bg-primary',
      };
    }

    return {
      text: 'text-white',
      bg: 'bg-secondary',
    };
  };

  const newContent = wpToTwClassesConverter(html, getButtonStylesBasedOnTheme(themeClass));

  return (
    <WpComponentWrapper themeClass={themeClass}>
      <div className="container mx-auto px-4">
        <div className="justify-center items-center h-full flex flex-row">
          <div dangerouslySetInnerHTML={{ __html: newContent }}></div>
        </div>
      </div>
    </WpComponentWrapper>
  );
};

NoImageEditor.propTypes = {
  themeClass: PropTypes.string,
  html: PropTypes.string,
};

NoImageEditor.defaultProps = {
  themeClass: 'secondary',
};

export default NoImageEditor;
