import React from 'react';
import { Container, Box } from '@material-ui/core';
import PropTypes from 'prop-types';

const Donate = ({ thankYou, somethingWentWrong }) => {
  return (
    <Container>
      <Box py={3} display="flex" flexDirection="row" justifyContent="center" alignItems="center">
        <Box dangerouslySetInnerHTML={{ __html: thankYou || somethingWentWrong }}></Box>
      </Box>
    </Container>
  );
};

Donate.propTypes = {
  thankYou: PropTypes.string,
  somethingWentWrong: PropTypes.string,
};

export default Donate;
