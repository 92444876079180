import React from 'react';
import Layout from '@org/components/layout';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import { graphql } from 'gatsby';
import Seo from '@org/components/seo';
import * as components from '@shared/components/wp-components';

const RenderComponent = ({ component: Component, ...attributes }) => <Component {...attributes} />;

const ComponentsPage = ({ data: { wpPage: data } }) => {
  const frontPageComponents = data.acfComponentPicker.picker
    .filter((cmp) => !!cmp.acfComponents)
    .map((cmp) => ({
      componentType: cmp.acfComponents.componentType,
      componentName:
        cmp.acfComponents.componentType[0].toUpperCase() + cmp.acfComponents.componentType.slice(1),
      id: cmp.id,
      ...cmp.acfComponents[cmp.acfComponents.componentType],
    }));

  return (
    <Layout>
      <Seo title={data.title} />
      {frontPageComponents && frontPageComponents.length
        ? frontPageComponents.map((component) => (
            <Box component="section" key={component.id}>
              {components[component.componentName] ? (
                <RenderComponent component={components[component.componentName]} {...component} />
              ) : (
                ''
              )}
            </Box>
          ))
        : ''}
    </Layout>
  );
};

ComponentsPage.propTypes = {
  data: PropTypes.object,
};

RenderComponent.propTypes = {
  component: PropTypes.func,
};

export const query = graphql`
  query componentsPage($id: String!) {
    wpPage(id: { eq: $id }) {
      id
      status
      title
      content
      slug
      uri
      acfComponentPicker {
        picker {
          ... on WpComponent {
            id
            acfComponents {
              componentType
              noImageEditor {
                themeClass
                html
              }
              defaultAdviser {
                html
              }
              donate {
                donateButtonLink
                donateButtonText
                donateSelectCurrency
                donateSelectAmount
                html
              }
              backFromPayment {
                somethingWentWrong
                thankYou
              }
              splitPanel {
                contentOrder
                themeClass
                html
                image {
                  localFile {
                    childImageSharp {
                      gatsbyImageData(width: 600, layout: CONSTRAINED)
                      original {
                        width
                        height
                      }
                    }
                  }
                }
              }
              backgroundImageWithContent {
                content
                image {
                  localFile {
                    childImageSharp {
                      gatsbyImageData(layout: FULL_WIDTH)
                      original {
                        width
                        height
                      }
                    }
                  }
                }
              }
              contentColumnsFullWidth {
                title
                themeClass
                columnLeft
                columnRight
                footer
              }
              columnCards {
                title
                themeClass
                cardLeft
                cardRight
              }
              embedFullWidth {
                media
              }
              imagesList {
                title
                imageList {
                  image {
                    localFile {
                      childImageSharp {
                        gatsbyImageData(width: 1600, layout: CONSTRAINED)
                      }
                    }
                  }
                }
              }
              imageTilesWithDescription {
                html
                tiles {
                  tile {
                    backgroundVariant
                    image {
                      localFile {
                        childImageSharp {
                          gatsbyImageData(width: 300, layout: CONSTRAINED)
                          original {
                            width
                            height
                          }
                        }
                      }
                    }
                  }
                }
              }
              team {
                image {
                  localFile {
                    childImageSharp {
                      gatsbyImageData(width: 600, layout: CONSTRAINED)
                      original {
                        width
                        height
                      }
                    }
                  }
                }
                teamMembers {
                  teamMember {
                    jobTitle
                    name
                    image {
                      localFile {
                        childImageSharp {
                          gatsbyImageData(width: 100, quality: 100, layout: CONSTRAINED)
                          original {
                            width
                            height
                          }
                        }
                      }
                    }
                  }
                }
              }
              editorWithImage {
                html
                image {
                  localFile {
                    childImageSharp {
                      gatsbyImageData(width: 900, layout: CONSTRAINED)
                      original {
                        width
                        height
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default ComponentsPage;
