import React from 'react';
import t from 'prop-types';

function EmbedVideo({ url }) {
  const youtubeId =
    Object.fromEntries(
      url
        ?.split('?')[1]
        .split('&')
        .map((el) => el.split('=')),
    )?.['v'] || null;

  return (
    <div className="relative" style={{ paddingTop: '56.25%' }}>
      {youtubeId && (
        <iframe
          title="video-embed"
          className="absolute inset-0 w-full h-full"
          frameBorder="0"
          src={`https://www.youtube.com/embed/${youtubeId}`}
        ></iframe>
      )}
    </div>
  );
}

EmbedVideo.propTypes = {
  url: t.string.isRequired,
};

export default EmbedVideo;
