export const wpToMaterialUiClassesConverter = (html) => {
  return html
    .replace(/<h1 class="p1"/g, '<h1 class="MuiTypography-root MuiTypography-h1"')
    .replace(/<h2 class="p1"/g, '<h2 class="MuiTypography-root MuiTypography-h2"')
    .replace(/<h3 class="p1"/g, '<h3 class="MuiTypography-root MuiTypography-h3"')
    .replace(/<h4 class="p1"/g, '<h4 class="MuiTypography-root MuiTypography-h4"')
    .replace(/<h5 class="p1"/g, '<h5 class="MuiTypography-root MuiTypography-h5"')
    .replace(/<h6 class="p1"/g, '<h6 class="MuiTypography-root MuiTypography-h6"')
    .replace(/<p class="p1"/g, '<p class="MuiTypography-root MuiTypography-p"')
    .replace(/<h1/g, '<h1 class="MuiTypography-root MuiTypography-h1"')
    .replace(/<h2/g, '<h2 class="MuiTypography-root MuiTypography-h2"')
    .replace(/<h3/g, '<h3 class="MuiTypography-root MuiTypography-h3"')
    .replace(/<h4/g, '<h4 class="MuiTypography-root MuiTypography-h4"')
    .replace(/<h5/g, '<h5 class="MuiTypography-root MuiTypography-h5"')
    .replace(/<h6/g, '<h6 class="MuiTypography-root MuiTypography-h6"')
    .replace(
      /<a/g,
      '<a class="MuiButtonBase-root MuiButton-root MuiButton-contained" type="button"',
    );
};
export const wpToTwClassesConverter = (html, classes) => {
  return html.replace(/<a/g, `<a class="btn ${classes.bg} ${classes.text}" type="button"`);
};

export const getRandomValueBetweenXY = (x, y) => {
  return Math.floor(Math.random() * (y - x + 1)) + x;
};

export default {
  wpToMaterialUiClassesConverter,
  wpToTwClassesConverter,
  getRandomValueBetweenXY,
};
