import React from 'react';
import PropTypes from 'prop-types';
import '@shared/components/wp-components/wp-components.css';
import cx from 'classnames';

const WpComponentWrapper = ({ children, themeClass = 'tirtiary', noPadding, fullHeight }) => {
  let themeClassProcessed = '';

  if (themeClass) {
    const textColour = `text-${themeClass}-contrast`;

    themeClassProcessed = `${textColour}`;
  }

  return (
    <div
      className={cx(
        'wp-component flex',
        { 'h-full': fullHeight },
        noPadding ? 'py-0 px-0' : 'py-6 px-0 md:py-12 md:px-8',
        themeClassProcessed,
      )}
    >
      {children}
    </div>
  );
};

WpComponentWrapper.propTypes = {
  children: PropTypes.object.isRequired,
  themeClass: PropTypes.string,
  themeClasses: PropTypes.object,
  noPadding: PropTypes.bool,
  fullHeight: PropTypes.bool,
};

export default WpComponentWrapper;
