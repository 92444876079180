import React from 'react';
import type from 'prop-types';
import cx from 'classnames';

function Card({ classes, content }) {
  return (
    <div className={cx('rounded p-16', classes)}>
      <div dangerouslySetInnerHTML={{ __html: content }}></div>
    </div>
  );
}

Card.propTypes = {
  content: type.string.isRequired,
  classes: type.string,
};

Card.defaultProps = {
  classes: '',
};

export default Card;
