import React, { useState, useEffect } from 'react';
import firebase from 'gatsby-plugin-firebase';
import { useCollectionData, useDocumentDataOnce } from 'react-firebase-hooks/firestore';
import useAuthState from '@shared/hooks/use-auth-state';
import { CircularProgress } from '@material-ui/core';
import Modal from '@shared/components/modal';
import Login from '@shared/components/login';
import DefaultAdviserSelect from '@shared/components/default-adviser-select';
import Alert from '@shared/components/alert';
import cx from 'classnames';
import Button from '@shared/components/button';

const DefaultAdviserComponent = () => {
  const advisersDefaults = {
    general: '',
    environment: '',
    climate: '',
    human: '',
    animal: '',
  };
  const defaultModalTitle = 'Sign in';

  const [user, userLoading] = useAuthState(firebase);
  // const { loggedIn } = useContext(LoggedInContext);
  const [defaultAdvisers, setDefaultAdvisers] = useState({ ...advisersDefaults });
  const [lastDefaultAdvisers, setLastDefaultAdvisers] = useState({ ...advisersDefaults });
  const [modalTitle, setModalTitle] = useState(defaultModalTitle);
  const [isSaving, setIsSaving] = useState(false);
  const [animating, setAnimating] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [showAlert, setShowAlert] = useState(false);

  const [userData] = useDocumentDataOnce(firebase.firestore().doc(`customers/${user?.uid}`), {
    snapshotListenOptions: { includeMetadataChanges: true },
  });

  useEffect(() => {
    if (
      userData?.defaultAdvisers &&
      Object.keys(userData?.defaultAdvisers).length &&
      Object.keys(userData?.defaultAdvisers).find((da) => userData?.defaultAdvisers[da] !== '') &&
      !Object.keys(defaultAdvisers).find((da) => defaultAdvisers[da] !== '')
    ) {
      setDefaultAdvisers(() => ({ ...userData.defaultAdvisers }));
      setLastDefaultAdvisers(() => ({ ...userData.defaultAdvisers }));
    }

    if (
      !userData &&
      Object.keys(defaultAdvisers).find((da) => defaultAdvisers[da] !== '') &&
      JSON.stringify(defaultAdvisers) !== JSON.stringify(lastDefaultAdvisers)
    ) {
      setDefaultAdvisers(() => ({ ...advisersDefaults }));
      setLastDefaultAdvisers(() => ({ ...advisersDefaults }));
    }
  }, [defaultAdvisers, userData, advisersDefaults, lastDefaultAdvisers]);

  const handleChangeAdviser = (type = 'general', event) => {
    if (!userLoading && user === null) {
      setAnimating(true);
      setTimeout(() => {
        setAnimating(false);
      }, 1000);
      return;
    }
    const obj = { ...defaultAdvisers };
    obj[type] = event.target.value;
    setDefaultAdvisers(() => obj);
    // setLastDefaultAdvisers(() => JSON.stringify({ ...obj }));
  };

  const handleSaveAction = async (type = 'general', v) => {
    if (!userLoading && user === null) {
      handleLoginClick('Please log in to save.');
      return;
    }
    setIsSaving(true);
    const obj = {};
    obj[`${type}Adviser`] = v;
    try {
      await firebase
        .firestore()
        .collection('customers')
        .doc(user?.uid)
        .set({ ...userData.defaultAdvisers, defaultAdvisers }, { merge: true });

      setShowAlert(true);

      setIsSaving(false);
    } catch (err) {
      console.log('Err', err);
      setIsSaving(false);
    }
    // setGeneralAdviser(() => v);
  };

  const [ngos, ngosLoading] = useCollectionData(firebase.firestore().collection('ngos'), {
    snapshotListenOptions: { includeMetadataChanges: true },
  });

  const handleLoginClick = (title) => {
    setModalTitle(() => title || defaultModalTitle);
    setIsOpen(() => true);
  };
  const handleOnCloseClick = () => {
    setIsOpen(() => false);
  };

  return (
    <div className="container">
      {!userLoading && user === null && (
        <div className="mb-6 text-center">
          <p
            className={cx(
              { 'animate-shake': animating },
              'px-4 py-3 text-center bg-primary bg-opacity-25 inline-block',
            )}
          >
            Please log in to start selecting, or see your previous selection{' '}
            <Button
              onClick={() => handleLoginClick(null)}
              className="bg-primary text-white hover:bg-primary-600 ml-2"
            >
              Log in
            </Button>
          </p>
          <Modal title={modalTitle} open={isOpen} canClose={true} onCloseClick={handleOnCloseClick}>
            <Login />
          </Modal>
        </div>
      )}
      <Alert show={showAlert} closeIn={4} onClose={() => setShowAlert(false)}>
        Saved selection
      </Alert>
      <div className="w-full grid grid-flow-row grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
        <DefaultAdviserSelect
          type="general"
          advisers={ngos?.filter((ngo) => ngo.categories.indexOf('general') > -1)}
          onAdviserChange={handleChangeAdviser}
          isLoading={ngosLoading}
          defaultAdviser={defaultAdvisers['general']}
        />
        <DefaultAdviserSelect
          type="environment"
          advisers={ngos?.filter((ngo) => ngo.categories.indexOf('environment') > -1)}
          onAdviserChange={handleChangeAdviser}
          isLoading={ngosLoading}
          defaultAdviser={defaultAdvisers['environment']}
        />
        <DefaultAdviserSelect
          type="climate"
          advisers={ngos?.filter((ngo) => ngo.categories.indexOf('climate') > -1)}
          onAdviserChange={handleChangeAdviser}
          isLoading={ngosLoading}
          defaultAdviser={defaultAdvisers['climate']}
        />
        <DefaultAdviserSelect
          type="human"
          advisers={ngos?.filter((ngo) => ngo.categories.indexOf('human') > -1)}
          onAdviserChange={handleChangeAdviser}
          isLoading={ngosLoading}
          defaultAdviser={defaultAdvisers['human']}
        />
        <DefaultAdviserSelect
          type="animal"
          advisers={ngos?.filter((ngo) => ngo.categories.indexOf('animal') > -1)}
          onAdviserChange={handleChangeAdviser}
          isLoading={ngosLoading}
          defaultAdviser={defaultAdvisers['animal']}
        />
        <div className="py-2 text-right">
          <Button
            variant="contained"
            className="bg-primary text-white"
            disabled={
              !defaultAdvisers ||
              JSON.stringify(lastDefaultAdvisers) === JSON.stringify(defaultAdvisers) ||
              ngosLoading
            }
            onClick={handleSaveAction}
          >
            <span>
              Save Selection {isSaving && <CircularProgress size={12} className="text-primary" />}
            </span>
          </Button>
        </div>
      </div>
    </div>
  );
};

export default DefaultAdviserComponent;
