import React from 'react';
import { MenuItem, Select, InputLabel, FormControl, CircularProgress } from '@material-ui/core';
import PropTypes from 'prop-types';

function DefaultAdvier({ type, defaultAdviser, disabled, isLoading, onAdviserChange, advisers }) {
  return (
    <FormControl color="secondary">
      {advisers ? (
        <React.Fragment>
          <InputLabel shrink id={`${type}-adviser-label`} className="capitalize">
            {type}
          </InputLabel>
          <Select
            labelId={`${type}-adviser-label`}
            label={`${type}`}
            id={`${type}-adviser`}
            value={defaultAdviser}
            onChange={(e) => onAdviserChange(type, e)}
            disabled={disabled || isLoading}
            displayEmpty
          >
            <MenuItem value="">{`Select your ${type} adviser`}</MenuItem>
            {advisers.map((ngo) => (
              <MenuItem key={ngo.id} value={ngo.id}>
                {ngo.name}
              </MenuItem>
            ))}
          </Select>
        </React.Fragment>
      ) : (
        <div className="text-center">
          <CircularProgress size={24} />
        </div>
      )}
    </FormControl>
  );
}

DefaultAdvier.propTypes = {
  type: PropTypes.string.isRequired,
  onAdviserChange: PropTypes.func.isRequired,
  advisers: PropTypes.array,
  isLoading: PropTypes.bool,
  disabled: PropTypes.bool,
  defaultAdviser: PropTypes.string,
};

DefaultAdvier.defaultProps = {
  defaultAdviser: '',
  isLoading: false,
  disabled: false,
  advisers: null,
};

export default DefaultAdvier;
