import React from 'react';
import PropTypes from 'prop-types';
import { wpToTwClassesConverter } from '@shared/utils';
import { GatsbyImage } from 'gatsby-plugin-image';
import cx from 'classnames';

const BgImgWithContent = ({ themeClass, content, image }) => {
  const imageFluid = image?.localFile?.childImageSharp?.gatsbyImageData || false;

  const newContent = wpToTwClassesConverter(content, {
    text: `bg-${themeClass}-contrast`,
    bg: `text-${themeClass}`,
  });

  return (
    <div className={cx(`text-${themeClass}-contrast relative`)}>
      <div className="w-full">
        {imageFluid ? <GatsbyImage image={imageFluid} alt="background image" /> : ''}
      </div>
      <div className="absolute left-0 top-0 mx-auto w-full h-full flex flex-col justify-center">
        <div
          className="container mx-auto px-4 text-tertiary pb-4"
          dangerouslySetInnerHTML={{ __html: newContent }}
        ></div>
      </div>
    </div>
  );
};

BgImgWithContent.propTypes = {
  themeClass: PropTypes.string,
  image: PropTypes.object,
  content: PropTypes.string,
};

BgImgWithContent.defaultProps = {
  themeClass: 'tertiary',
};

export default BgImgWithContent;
