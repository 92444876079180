import React from 'react';
import PropTypes from 'prop-types';
import WpComponentWrapper from '@shared/components/wp-components/wp-component-wrapper';

const ContentColumnsFullWidth = ({ title, columnLeft, columnRight, footer, themeClass }) => {
  return (
    <WpComponentWrapper themeClass={themeClass}>
      <div className="container mx-auto">
        <h4 className="mb-4 text-center" dangerouslySetInnerHTML={{ __html: title }}></h4>
        <div className="flex flex-wrap mb-8">
          <div className="flex-1">
            <div className="items-center h-full flex flex-row">
              <div
                className="m-0 max-w-sm mx-0 block"
                dangerouslySetInnerHTML={{ __html: columnLeft }}
              ></div>
            </div>
          </div>
          <div className="flex-1">
            <div className="items-center h-full flex flex-row justify-end">
              <div
                className="m-0 max-w-sm mx-0 block"
                dangerouslySetInnerHTML={{ __html: columnRight }}
              ></div>
            </div>
          </div>
        </div>
        <div
          className="mt-4 max-w-xl text-center mx-auto"
          dangerouslySetInnerHTML={{ __html: footer }}
        ></div>
      </div>
    </WpComponentWrapper>
  );
};

ContentColumnsFullWidth.propTypes = {
  title: PropTypes.string,
  themeClass: PropTypes.string,
  footer: PropTypes.string,
  columnLeft: PropTypes.string,
  columnRight: PropTypes.string,
};

export default ContentColumnsFullWidth;
