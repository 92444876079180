import React from 'react';
import PropTypes from 'prop-types';
import { GatsbyImage } from 'gatsby-plugin-image';

const ImageTilesWithDescription = ({ tiles, html }) => {
  const imgList = tiles.map(({ tile }) => ({
    img: tile.image?.localFile?.childImageSharp?.gatsbyImageData,
    size: tile.image?.localFile?.childImageSharp?.original,
    id: tile.image?.localFile?.childImageSharp?.gatsbyImageData?.images?.fallback.src,
    variant: tile.backgroundVariant,
  }));

  // const newHtml = wpToMaterialUiClassesConverter(html);

  return (
    <div className="relative container mx-auto px-4 mt-6 flex flex-wrap justify-center">
      <div className="grid grid-cols-5 w-full gap-3 mb-5">
        {imgList &&
          imgList.map((tile, index) => (
            <div
              key={tile.id}
              style={{ maxWidth: tile.size.width }}
              className={`bg-${tile.variant || 'primary'} flex place-self-center`}
            >
              <GatsbyImage image={tile.img} alt={`tile$`} />
            </div>
          ))}
      </div>
      <div dangerouslySetInnerHTML={{ __html: html }}></div>
    </div>
  );
};

ImageTilesWithDescription.propTypes = {
  tiles: PropTypes.array,
  html: PropTypes.string,
};

export default ImageTilesWithDescription;
