import React from 'react';
import PropTypes from 'prop-types';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import Expandable from '@shared/components/expandable';

const EditorWithImage = ({ image, html }) => {
  const mainImg = getImage(image?.localFile);

  return (
    <div className="container mx-auto px-4">
      <div className="justify-center items-center h-full flex flex-col">
        <Expandable>
          <div className="mb-12" dangerouslySetInnerHTML={{ __html: html }}></div>
        </Expandable>
        <div className="w-full py-4 flex justify-center">
          {mainImg && (
            <GatsbyImage
              imgClassName="max-h-full max-w-full"
              style={{ width: `${mainImg?.width}px` }}
              className="max-w-full"
              image={mainImg}
              alt="Allies"
            />
          )}
        </div>
      </div>
    </div>
  );
};

EditorWithImage.propTypes = {
  image: PropTypes.object,
  html: PropTypes.string,
};

export default EditorWithImage;
