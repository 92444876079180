import React, { useState } from 'react';
import { Container, Box, Button, TextField, MenuItem } from '@material-ui/core';
import PropTypes from 'prop-types';
import WpComponentWrapper from '@shared/components/wp-components/wp-component-wrapper';
import { wpToMaterialUiClassesConverter } from '@shared/utils';
import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { useLocation } from '@reach/router';

const theme = createMuiTheme({
  palette: {
    type: 'dark',
  },
});

const Donate = ({
  donateButtonLink,
  donateButtonText,
  html,
  donateSelectCurrency,
  donateSelectAmount,
}) => {
  const location = useLocation();
  const currencies = donateSelectCurrency;
  const amounts = donateSelectAmount.map((itm) => parseInt(itm, 10));
  const newHtml = wpToMaterialUiClassesConverter(html);

  const [currency, setCurrency] = useState(donateSelectCurrency[0]);
  const [amount, setAmount] = useState(donateSelectAmount[0]);

  const handleCurrencyChange = (e) => {
    setCurrency(e.target.value);
  };
  const handleAmountChange = (e) => {
    setAmount(() => e.target.value);
  };

  const handleGoToPayment = (e) => {
    const baseUrl = donateButtonLink;
    const returnTo = `${location.href}back-from-payment`;
    const currencyInd = currencies.indexOf(currency);
    const amountInd = amounts.indexOf(amount);
    const linkTo = `${baseUrl}?returnTo=${encodeURIComponent(
      returnTo,
    )}&amountSegment=${amountInd}&currencySegment=${currencyInd}`;
    if (typeof document !== 'undefined') {
      document.location.href = linkTo;
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <WpComponentWrapper themeClass="tertiary">
        <Container>
          <Box display="flex" flexDirection="row" justifyContent="center" alignItems="center">
            <Box>
              <Box dangerouslySetInnerHTML={{ __html: newHtml }}></Box>
              <Box display="flex" flexDirection="row">
                <Box mr={2}>
                  <TextField
                    select
                    id="amount-select"
                    value={amount}
                    onChange={handleAmountChange}
                    color="primary"
                  >
                    {amounts.map((amnt) => (
                      <MenuItem key={amnt} value={amnt}>
                        {amnt}
                      </MenuItem>
                    ))}
                  </TextField>
                </Box>
                <Box mr={2}>
                  <TextField
                    select
                    id="currency-select"
                    value={currency}
                    onChange={handleCurrencyChange}
                    color="primary"
                  >
                    {currencies.map((amnt) => (
                      <MenuItem key={amnt} value={amnt}>
                        {amnt}
                      </MenuItem>
                    ))}
                  </TextField>
                </Box>
                <Box>
                  <Button color="primary" variant="contained" onClick={handleGoToPayment}>
                    {donateButtonText}
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>
        </Container>
      </WpComponentWrapper>
    </ThemeProvider>
  );
};

Donate.propTypes = {
  donateSelectCurrency: PropTypes.arrayOf(PropTypes.string),
  donateSelectAmount: PropTypes.arrayOf(PropTypes.string),
  html: PropTypes.string,
  donateButtonLink: PropTypes.string,
  donateButtonText: PropTypes.string,
};

export default Donate;
