import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

function Expandable({ children }) {
  const [seeMore, setSeeMore] = useState(false);
  const handleSeeMore = () => {
    setSeeMore(() => !seeMore);
  };

  return (
    <div
      className={cx(
        { 'max-h-80': !seeMore },
        'transition-all transform duration-500 overflow-hidden pb-12',
      )}
    >
      {children}
      <div className="absolute bottom-0 left-0 w-full h-12 text-center bg-white bg-opacity-50">
        <button
          className="transition-all duration-300 shadow-material bg-secondary text-white p-2 my-1 rounded"
          onClick={handleSeeMore}
        >
          {!seeMore && <span className="pl-2">Read more</span>}{' '}
          <KeyboardArrowUpIcon className={cx({ 'transform rotate-180': !seeMore })} />
        </button>
      </div>
    </div>
  );
}

Expandable.propTypes = {
  children: PropTypes.node,
};

export default Expandable;
