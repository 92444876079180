import React from 'react';
import { Container, Box } from '@material-ui/core';
import PropTypes from 'prop-types';
import DefaultAdvisersComponent from '@shared/components/default-advisers-component';

const DefaultAdviser = ({ html }) => {
  return (
    <Container>
      <Box py={0} display="flex" flexDirection="column" justifyContent="center" alignItems="center">
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          className="mb-6"
          dangerouslySetInnerHTML={{ __html: html }}
        ></Box>
        <DefaultAdvisersComponent />
      </Box>
    </Container>
  );
};

DefaultAdviser.propTypes = {
  themeClass: PropTypes.string,
  html: PropTypes.string,
};

DefaultAdviser.defaultProps = {
  themeClass: '',
};

export default DefaultAdviser;
