import React from 'react';
import PropTypes from 'prop-types';
import VideoEmbed from '@shared/components/embed-video';
import cx from 'classnames';
import { getRandomValueBetweenXY } from '@shared/utils';

const EmbedFullWidth = ({ media }) => {
  return (
    <div>
      {media && (
        <div className="w-full sm:w-10/12 md:w-8/12 lg:w-1/2 mx-auto my-6 px-4">
          <div
            className={cx('bg-tertiary p-8 rounded-lg')}
            style={{
              borderTopLeftRadius: `${getRandomValueBetweenXY(40, 90)}px ${getRandomValueBetweenXY(
                40,
                90,
              )}px`,
              borderTopRightRadius: `${getRandomValueBetweenXY(40, 90)}px ${getRandomValueBetweenXY(
                40,
                90,
              )}px`,
              borderBottomRightRadius: `${getRandomValueBetweenXY(
                40,
                90,
              )}px ${getRandomValueBetweenXY(40, 90)}px`,
              borderBottomLeftRadius: `${getRandomValueBetweenXY(
                40,
                90,
              )}px ${getRandomValueBetweenXY(40, 90)}px`,
            }}
          >
            <VideoEmbed url={media} />
          </div>
        </div>
      )}
    </div>
  );
};

EmbedFullWidth.propTypes = {
  media: PropTypes.string,
};

export default EmbedFullWidth;
