import React from 'react';
import type from 'prop-types';
import { GatsbyImage } from 'gatsby-plugin-image';
import { CarouselProvider, Dot, Slider, Slide, ButtonBack, ButtonNext } from 'pure-react-carousel';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import 'pure-react-carousel/dist/react-carousel.es.css';

function ImageCarousel({ imageList, title }) {
  const imgList =
    imageList &&
    imageList.map((el) => el.image?.localFile?.childImageSharp?.gatsbyImageData || false);
  console.log('imgList', imgList);
  return (
    <div>
      {title && (
        <div className="container mx-auto">
          <h1 className="px-4 text-secondary">{title}</h1>
        </div>
      )}
      <div className="relative mx-auto" style={{ maxWidth: '1500px' }}>
        {imgList && (
          <CarouselProvider
            naturalSlideWidth={100}
            isIntrinsicHeight
            totalSlides={imgList.length}
            isPlaying
            interval={6000}
          >
            <Slider>
              {imgList &&
                imgList.length &&
                imgList.map((img, index) => (
                  <Slide key={img.images?.fallback?.src} className="cursor-move">
                    <div className="w-full">
                      <GatsbyImage image={img} alt={`slide${index + 1}`} />
                    </div>
                  </Slide>
                ))}
            </Slider>
            <div className="absolute bottom-0 left-0 h-8 w-full flex content-center justify-center">
              {imgList &&
                imgList.length &&
                imgList.map((img, index) => (
                  <Dot
                    key={img.images?.fallback?.src}
                    slide={index}
                    className="px-1 text-black text-opacity-50 disabled:text-opacity-100"
                  >
                    <FiberManualRecordIcon
                      style={{ width: '12px', height: '12px' }}
                      className="fill-current"
                    />
                  </Dot>
                ))}
            </div>
            <div className="absolute top-0 left-0 h-full flex content-center">
              <ButtonBack className="focus:outline-none px-2">
                <ChevronLeft style={{ fontSize: '50px' }} />
              </ButtonBack>
            </div>
            <div className="absolute top-0 right-0 h-full flex content-center">
              <ButtonNext className="focus:outline-none px-2">
                <ChevronRight style={{ fontSize: '50px' }} />
              </ButtonNext>
            </div>
          </CarouselProvider>
        )}
      </div>
    </div>
  );
}

ImageCarousel.propTypes = {
  title: type.string,
  imageList: type.array,
};

export default ImageCarousel;
